import React, { Component } from 'react';
import Play from "../components/Play.js"
import Face from "../components/Face.js"

class Header extends Component {
  render() {
    return (
      <>
        <Face />
        <Play />
      </>
    )
  }
}

export default Header;