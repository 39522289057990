import React, { Component } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import ImageZoom from 'react-medium-image-zoom';

class Item extends Component {
  render() {
    return (
      <div className="columns block-item Resume-item">
        <div className="column is-4">
          <h2 className="title is-4 Resume-item-title">{this.props.title}</h2>
        </div>
        <div className="column">
          {this.props.questions.map(question => (
            <div className="Resume-question" key={Math.random()}>
              <div className="title is-4">{question.title}</div>

              <div className="content">
                {
                  question.images &&
                  <div className="Resume-images" key={Math.random()}>

                  {question.images.map(image => (
                    <React.Fragment key={Math.random()}>
                      <VisibilitySensor>
                        <ImageZoom
                          defaultStyles={{
                            overlay: {
                              background: "white",
                              transition: "none"
                            },
                          }}
                          image={{
                            src: image,
                          }}
                          zoomImage={{
                            src: image,
                            className: "image-zoomed"
                          }}
                        />
                      </VisibilitySensor>
                    </React.Fragment>

                    
                  ))}
                  </div>
                }

                {question.answer.map(answer => (
                  <p key={Math.random()}>
                    {answer}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default Item;