import React, { Component } from 'react';
import Photo from "../assets/images/face.jpg";

class Face extends Component {
  render() {
    return (
      <figure className="Face">
        <img src={Photo} alt="" />
      </figure>
    )
  }
}

export default Face;