import React, { Component } from 'react';
import Header from "./layout/Header.js";
import Resume from "./layout/Resume/Resume.js";
import Projects from "./layout/Projects/Projects.js";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Resume />
        <Projects />
      </div>
    );
  }
}

export default App;
