import React, {useState, useEffect } from 'react';
import Modal from 'react-modal';


const Thumbnail = (props) => {
  const image = props.img.image;
  const color = props.color;

  const [modalOpen, setModalOpen] = useState(false);
  const [figureClass, setFigureClass] = useState("");

  function openModal() {
    setModalOpen(true);
    setFigureClass("is-open");
  }

  function closeModal() {
    setModalOpen(false);
    setFigureClass("");
  }

  return (
    <>
      <figure className={"Thumbnail " + figureClass} onClick={openModal}>
        <img src={image} className="image" alt="" />
      </figure>

      <Modal
        isOpen={modalOpen}
        style={color && {overlay: {backgroundColor: color}}}
        onRequestClose={closeModal}
        closeTimeoutMS={0}
        contentLabel="Something"
        portalClassName="Modal"
        overlayClassName="Modal-overlay"
        className="Modal-content"
        bodyOpenClassName="is-open"
        htmlOpenClassName="is-open"
        ariaHideApp={false}
        shouldFocusAfterRender={true}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        parentSelector={() => document.body}
      >
        <figure onClick={closeModal}>
          <img src={image} className="image" alt="" />
        </figure>
      </Modal>
    </>
  )
}

export default Thumbnail;