import React, { Component } from 'react';
import Data from "./Data.json";
import Item from "./Item.js";

class Resume extends Component {
  render() {
    return (
      <>
      </>
    )
  }
}

export default Resume;