import React, { Component } from 'react';
import Projects from "./Projects.json";
import Project from "./Project.js";

class Work extends Component {
  render() {
    return (
      <div className="block Projects">
        <div className="container">
          <div className="columns">
            <div className="column is-offset-1 is-10">
              <h1 className="subtitle is-2 block-subtitle Work-subtitle">Here’s some of</h1>
              <h1 className="title is-1 block-title Work-title">My best (recent) work</h1>
              <hr />
            </div>
          </div>

          <div className="Projects">
            {Projects.projects.map(project => (
              <Project {...project} key={Math.random()} />
            ))}
          </div>

          <div className="More">
            <div className="columns">
              <div className="column is-offset-1 is-10">
                <h1 className="title is-1 block-title Work-title">Work experience</h1>
                <hr />
              </div>
            </div>
            <div className="columns">
              <div className="column is-offset-1 is-10">
                <div className="content">
                  <p>There's a lot more I've done over the past 14 years. Have a look at my work history and my dribbble profile.</p>
                </div>
                <div className="columns">
                  <div className="column">
                    <div className="content">
                      <p><a href="https://www.notion.so/jonnotie/Resume-71d7ad84cde74fdba5c676340300897c" target="_blank" rel="noopener noreferrer">Read more about my history →</a></p>
                    </div>
                  </div>
                  <div className="column">
                    <div className="content">
                      <p><a href="https://dribbble.com/jonnotie" target="_blank" rel="noopener noreferrer">Check out my dribbble profile →</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="Final">
            <div className="columns">
              <div className="column is-offset-1 is-10">
                <h1 className="title is-1 block-title Work-title">Thanks for reading</h1>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Work;