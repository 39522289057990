import React, { Component } from 'react';
import Icon from "../assets/images/play.svg";

class Play extends Component {
  render() {
    return (
      <>
        <a className="Play" target="_blank" rel="noopener noreferrer" href="https://jonnotie.wistia.com/medias/91yss1oiqt">
          <img src={Icon} className="Play-icon" alt="" />
          <div className="Play-text">
            <span>View timelapse</span>
            <span>of how I made this</span>
          </div>
        </a>
      </>
    )
  }
}

export default Play;