import React, { Component } from 'react';
// import Img from 'react-image';
import VisibilitySensor from 'react-visibility-sensor';
import Thumbnail from '../../components/Thumbnail.js'



class Project extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  render() {
    return (
      <div className="Project">
      
        <div className="columns Project-header">
          <div className="column is-10 is-offset-1">
            <h2 className="title is-4 Project-title">{this.props.title}</h2>
            
            <div className="columns">
              <div className="column is-8">
                <div className="content">
                  <p>{this.props.description}</p>
                  {
                    this.props.link &&
                    <p><a target="blank" rel="noopener noreferrer" href={"http://" + this.props.link}>{this.props.link} →</a></p>
                  }
                </div>
              </div>
              {
                
                this.props.skills &&
                
                <div className="column">
                  <div className="Project-skills content">
                    <ul>
                    {this.props.skills.map(skill => (
                      <li key={Math.random()}>
                        – {skill}
                      </li>
                    ))}
                    </ul>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>

        <div className="columns is-multiline Project-images">
          
          {this.props.images.map(image => (
            <div className={`column is-${image.columns}`} key={Math.random()}>
              <VisibilitySensor>
                <Thumbnail img={image} color={this.props.color} />
              </VisibilitySensor>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default Project;